<template>
  <div>
    <div class="accountHead">
      <img src="@/assets/accountHeadPic.png" alt style="width: 75px" />
      <div class="accountInfo">账号信息</div>
    </div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="姓名" prop="realName">
        <el-input
          v-model="form.realName"
          maxlength="13"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="form.gender">
          <el-radio label="1">男</el-radio>
          <el-radio label="0">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="账号" prop="account">
        <el-input v-model="form.account" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button v-throttle type="primary" @click="reviseUserInfo('form')"
          >保存修改</el-button
        >
      </el-form-item>
    </el-form>
    <div class="accountTail">
      <img src="@/assets/accountPic.png" alt style="width: 650px" />
    </div>
  </div>
</template>
<script>
import { getUserDetails, reviseUserDetails } from '@/api/account/accountApi.js'
export default {
  data() {
    //校验规则
    var checkName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('姓名不能为空'))
      } else {
        if (this.regularName(value)) {
          callback(new Error('不能包含特殊字符'))
        } else {
          callback()
        }
      }
    }

    var checkAccount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('账号不能为空'))
      }
      setTimeout(() => {
        console.log(value, 'val')
        if (value.length < 6 || value.length > 20) {
          callback(new Error('账号必须为6-20位数字或英文'))
        } else {
          if (this.regularAccount(value)) {
            callback(new Error('账号必须为6-20位数字或英文'))
          } else {
            callback()
          }
        }
      }, 100)
    }
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('手机号不能为空'))
      } else {
        if (!this.regularPhone(value)) {
          callback(new Error('手机号格式有误'))
        } else {
          callback()
        }
      }
    }
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error('邮箱不能为空'))
      } else {
        if (!this.regularEmail(value)) {
          callback(new Error('邮箱格式有误'))
        } else {
          callback()
        }
      }
    }
    //data
    return {
      form: {
        realName: '',
        gender: '',
        account: '',
        phone: '',
        email: '',
      },
      rules: {
        realName: [{ validator: checkName, trigger: 'blur' }],
        account: [{ validator: checkAccount, trigger: 'blur' }],
        phone: [{ validator: checkPhone, trigger: 'blur' }],
        email: [{ validator: checkEmail, trigger: 'blur' }],
      },
    }
  },

  mounted() {
    this.getUserInfo()
  },

  methods: {
    //获取用户信息
    getUserInfo() {
      getUserDetails().then((res) => {
        let { code, data } = res
        this.form.realName = data.nickName
        if (data.gender != null) {
          this.form.gender = data.gender.toString()
        }
        this.form.account = data.account
        this.form.phone = data.phone
        this.form.email = data.email
      })
    },

    //修改用户信息(保存)
    reviseUserInfo(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证成功
          let userInfoParam = {
            nickName: this.form.realName,
            gender: this.form.gender,
            account: this.form.account,
            phone: this.form.phone,
            email: this.form.email,
          }

          reviseUserDetails(userInfoParam).then((res) => {
            let { success } = res
            if (success) {
              this.$message({
                message: '保存成功',
                type: 'success',
              })
              setTimeout(() => {
                this.$router.back()
              }, 700)
            } else {
              //保存失败
            }
          })
        } else {
          return false
        }
      })
    },

    //账号正则(只能含数字和英文)
    regularAccount(val) {
      return /[^a-zA-Z0-9]/g.test(val)
    },

    //手机号正则
    regularPhone(val) {
      return /^1[3456789]\d{9}$/.test(val)
    },

    //邮箱正则
    regularEmail(val) {
      return /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
        val
      )
    },

    //特殊字符正则
    regularName(val) {
      return /[`~!@ #$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im.test(
        val
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.el-button {
  width: 300px;
  border-radius: 22px 22px 22px 22px;
  margin-left: 50px;
}
.el-form {
  width: 500px;
  margin-left: 33%;
  margin-top: 40px;
}
.accountHead {
  display: block;
  text-align: center;
  margin-top: 100px;
  .accountInfo {
    font-size: 16px;
    font-weight: bold;
  }
}
.accountTail {
  text-align: center;
}
</style>
